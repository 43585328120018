import { useFormik } from "formik"
import * as yup from "yup"
import React, { useCallback } from "react"
import { message, notification } from "antd"
import { useTranslation } from "react-i18next"
import Head from "next/head"
import { firebase, theme } from "@project/shared"
import styled from "styled-components"
import * as Sentry from "@sentry/node"
import RestrictedRoute from "../../withRestrictedRoute"
import { CloseCircleFilled } from "@ant-design/icons"
import dynamic from "next/dynamic"
const TextField = dynamic(
  () => import("@project/shared").then((mod) => mod.TextField),
  { ssr: false }
)
const Button = dynamic(
  () => import("@project/shared").then((mod) => mod.Button),
  { ssr: false }
)
interface LoginType {
  email: string
  password: string
}

const Container = styled.div`
  background: ${theme.bgColor};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & .container-content {
    display: flex;
    flex-direction: column;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  cursor: pointer;
`

const LoginWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${theme.darkGrey};
  background: ${theme.base};
  padding: 1rem 2rem;
  border-radius: 2px;
  & .login-text-field {
    & label {
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 8px;
    }
  }
`
const TextFieldWrapper = styled.div`
  margin-top: 35px;
`
const StyledButton = styled(Button)`
  height: 40px;
  margin: auto;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InputFieldWrapper = styled.div`
  margin-bottom: 25px;
`

const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)

  const handleLoginFormSubmit = () => {
    handleLogin()
  }

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Please enter your e-mail address"))
      .required(t("Please enter")),
    password: yup
      .string()
      .required(t("Please enter"))
      .max(20, "パスワード半角英数字6文字以上20文字以内で入力してください。")
      .min(6, "パスワード半角英数字6文字以上20文字以内で入力してください。"),
  })

  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  })

  const timeout = window.localStorage.getItem("sessionExpired")
  if (timeout) {
    notification.warning({
      message: t("Your session has expired. Login again"),
    })
    window.localStorage.removeItem("sessionExpired")
  }

  const handleLogin = useCallback(async () => {
    setLoading(true)
    try {
      const data = await firebase
        .auth()
        .signInWithEmailAndPassword(formik.values.email, formik.values.password)
      if (!data || !data.user || !firebase.auth().currentUser) {
        return
      }
    } catch (error) {
      const errorCode = error.code
      if (errorCode === "auth/user-not-found") {
        message.error({
          key: "2",
          content: t("Email address or password does not match."),
          icon: <CloseCircleFilled onClick={() => message.destroy("2")} />,
        })
      } else if (errorCode === "auth/wrong-password") {
        message.error({
          key: "3",
          content: t("Email address or password does not match."),
          icon: <CloseCircleFilled onClick={() => message.destroy("3")} />,
        })
      } else if (errorCode === "auth/invalid-email") {
        message.error({
          key: "4",
          content: t("Email address or password does not match."),
          icon: <CloseCircleFilled onClick={() => message.destroy("4")} />,
        })
      } else {
        message.error({
          key: "5",
          content: t("An error has occurred. Please try again later."),
          icon: <CloseCircleFilled onClick={() => message.destroy("5")} />,
        })
      }
      Sentry.captureException(error)
    }
    setLoading(false)
  }, [formik.values])

  return (
    <>
      <Head>
        <title>Sales Agent | {t("Login")}</title>
      </Head>
      <Container>
        <div className="container-content">
          <LogoWrapper>
            <a href={process.env.NEXT_PUBLIC_CONSUMER_HOST} target={"_blank"}>
              <img src="/assets/icons/logo.svg" alt="logo" width="260" />
            </a>
          </LogoWrapper>
          <LoginWrapper>
            <TextFieldWrapper>
              <form onSubmit={formik.handleSubmit}>
                <InputFieldWrapper>
                  <TextField
                    name="email"
                    error={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("Email")}
                    placeholder={t("Email")}
                    height="40px"
                    width="340px"
                    className="login-text-field"
                  />
                </InputFieldWrapper>
                <InputFieldWrapper>
                  <TextField
                    name="password"
                    type="password"
                    error={formik.touched.password && formik.errors.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("Password")}
                    placeholder={t("Password")}
                    className="login-text-field"
                    height="40px"
                    width="340px"
                  />
                </InputFieldWrapper>
                <StyledButton htmlType="submit" loading={loading} width="96px">
                  {t("Login")}
                </StyledButton>
              </form>
            </TextFieldWrapper>
          </LoginWrapper>
        </div>
      </Container>
    </>
  )
}

export default RestrictedRoute(LoginPage)
